// Constants
import { ADDONS, COMPANY_TYPES, PACKAGES, ROLES } from '@/constants'
// Components
import BrandCardCentered from '@/components/ui/BrandCardCentered'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
// Vuex
import { mapActions, mapMutations, mapGetters } from 'vuex'
// Utils
import { get, isNil } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'Register',
  components: { BrandCardCentered, FormButtons },
  mixins: [validationMixin, formMixin, uiMixin],

  data() {
    return {
      // Form
      formFields: {
        placeName: null,
        name: null,
        email: null,
        password: null,
        useConditions: null
      },
      formFieldsValidations: {
        placeName: {
          required: 'Campo obligatorio'
        },
        name: {
          required: 'Campo obligatorio'
        },
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email'
        },
        password: {
          required: 'Campo obligatorio',
          minLength: 'Debe contener al menos 6 caracteres'
        },
        useConditions: {
          required: 'Debe aceptar las condiciones de uso'
        }
      },
      togglePassword: false // to show loading in action buttons
    }
  },
  computed: {
    ...mapGetters('app', ['referredId']),
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    acceptButtonVOptions() {
      return {
        block: true,
        large: true
      }
    }
  },
  methods: {
    ...mapActions('authentication', ['registerNewUser']),
    ...mapMutations('app', ['setReferred']),
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      try {
        // Posible "asociación" con marca (brandId)
        const brandId = get(this.$route, 'query.brand', null)

        // Creamos usuario, compañía y establecimiento
        await this.registerNewUser({
          user: {
            brand: getEnvironmentVariable('VUE_APP_BRAND'),
            email: this.formFields.email,
            password: this.formFields.password,
            displayName: this.formFields.name,
            role: ROLES.horeca_admin.value,
            firstAccess: true
          },
          userClaims: {
            role: ROLES.horeca_admin.value
          },
          place: {
            brand: getEnvironmentVariable('VUE_APP_BRAND'),
            name: this.formFields.placeName
          },
          company: {
            brand: getEnvironmentVariable('VUE_APP_BRAND'),
            type: COMPANY_TYPES.horeca,
            ...(!isNil(brandId) ? { brandId } : {}),
            ...(!isNil(this.referredId) ? { referredId: this.referredId } : {})
          },
          addOns: [
            {
              packageId: PACKAGES.free.value,
              subscriptionData: {
                amount: 0,
                currency: 'EUR',
                priceId: 'custom',
                period: 36500
              }
            }
          ],
          addOnConfigs: [
            {
              target: 'place',
              id: ADDONS.place,
              configFields: {
                name: this.formFields.placeName
              }
            },
            {
              target: 'place',
              id: ADDONS.contact,
              configFields: {
                email: this.formFields.email
              }
            },
            {
              target: 'company',
              id: ADDONS.company,
              configFields: {
                // Configuraciones de comisión por defecto
                relationshipCommissions: {
                  referrals: {
                    type: 'value',
                    value: 10
                  }
                }
              }
            }
          ]
        })

        // Eliminamos (reseteamos el referido)
        this.setReferred(null)
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
        // Cerramos diálogo de carga
        this.modifyAppLoadingLayer({
          visible: false
        })
        // Close session
        await this.closeSession()
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      placeName: {
        required
      },
      name: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      },
      useConditions: {
        required
      }
    }
  }
}
